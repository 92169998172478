import React, { useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { StateSelectListBox } from 'components';
import { useForm } from 'react-hook-form';

import { showError } from 'app/AccountOnboard/Components/Components';
import { useOnboardStageFinished } from 'state/OnboardState';
import { ONBOARD_STAGE_AGREE_TO_TERMS } from 'app/AccountOnboard/OnboardStages';
import { useAccount, useUserAction } from '../../../state/User/UserState';
import SchoolAutoSuggest from '../../SchoolAutoSuggest/SchoolAutoSuggest';

const CheckboxContainer = styled.div`

    .checkbox-item {
        /* width: 50%; */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;

        @media (max-width: 700px) {
            width: 100%;
        }
    }

    .container {
        display: block;
        position: relative;
        margin: 0;
        padding-left: 35px;
        margin-bottom: 15px;
        cursor: pointer;
        font-weight: 500;
        font-size: 18px;
        line-height: normal;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        max-height: 30px;

        .title {
            font-weight: 400;
            font-size: 16px;
            line-height: normal;
            text-align: justify;
            margin: 5px 0 0 15px;

            a {
                color: black;
                text-decoration: underline;
                white-space: nowrap;
            }
        }
    }

    /* Hide the browser's default checkbox */

    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 32px;
        width: 32px;
        background-color: #fff;
        border: 2px solid #121651;
        //border-radius: 99px;
    }

    /* On mouse-over, add a grey background color */

    .container:hover input ~ .checkmark {
        background: #1F2365;
    }

    /* When the checkbox is checked, add a background */

    .container input:checked ~ .checkmark {
        background: #1F2365;
    }

    /* Create the checkmark/indicator (hidden when not checked) */

    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */

    .container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */

    .container .checkmark:after {
        left: 12px;
        top: 8px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .mobile-style {
        @media (max-width: 400px) {
            margin-bottom: 30px;
        }
    }
`;

const CheckBoxGrid = styled.div`
    .checkbox-grid-item {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        //margin: 20px 10px 0;
        align-content: center;

        @media (max-width: 400px) {
            //margin: 20px 5px 0;
        }

        .checkbox-item {
            /* width: 50%; */
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;

            @media (max-width: 700px) {
                width: 100%;
            }
        }

        .container {
            display: block;
            position: relative;
            margin: 0;
            padding-left: 35px;
            margin-bottom: 15px;
            cursor: pointer;
            font-weight: 500;
            font-size: 18px;
            line-height: normal;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            max-height: 30px;

            .title {
                font-weight: 400;
                font-size: 16px;
                line-height: normal;
                text-align: justify;
                margin: 5px 0 0 15px;

                a {
                    color: white;
                    text-decoration: underline;
                    white-space: nowrap;
                }
            }
        }

        /* Hide the browser's default checkbox */

        .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 32px;
            width: 32px;
            background-color: #fff;
            border: 2px solid #121651;
            //border-radius: 99px;
        }

        /* On mouse-over, add a grey background color */

        .container:hover input ~ .checkmark {
            background: #1F2365;
        }

        /* When the checkbox is checked, add a background */

        .container input:checked ~ .checkmark {
            background: #1F2365;
        }

        /* Create the checkmark/indicator (hidden when not checked) */

        .checkmark:after {
            content: '';
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */

        .container input:checked ~ .checkmark:after {
            display: block;
        }

        /* Style the checkmark/indicator */

        .container .checkmark:after {
            left: 12px;
            top: 8px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        .mobile-style {
            @media (max-width: 400px) {
                margin-bottom: 30px;
            }
        }
    }
`;

const AgreeToTerms = (props) => {
    const { is_parent_account } = useAccount();
    const [state, setState] = useState(null);
    const { register, handleSubmit, watch, errors: formErrors, setError, clearErrors } = useForm({});
    const stageFinished = useOnboardStageFinished();
    const { updateUser } = useUserAction();

    const onSubmit = (data) => {
        console.log(data.grade);
        if (!state) {
            setError('state', { type: 'manual', message: 'This field is required' });
            return;
        }
        const payload = { ...data, state };
        return stageFinished(ONBOARD_STAGE_AGREE_TO_TERMS, payload)
            .then(() => {
                // update the user's state
                updateUser();
            })
            .catch((error) => {
                console.log('Error:', error);
            });

    };

    const selectState = (data) => {
        // console.log(data);
        setState(data.abbreviation);
        clearErrors('state');
    };

    let name_description = '';
    if (is_parent_account) {
        name_description = '(This is the name your child will see.)';
    } else {
        name_description = '(This is the name your students will see on Muzology.)';
    }

    const makeCheckbox = () => {
        let checkbox = Array(10).fill(0);
        return checkbox.map((x, index) => {
            let label = index + 3;
            let value = String(index + 3);
            if (index === 0) {
                value = '3';
                label = 'K-3';
            } else if (index === 8) {
                label = '11-12';
                value = '11';
            } else if (index === 9) {
                label = 'Other';
                value = '12';
            }
            return (
                <div key={index} className='checkbox-grid-item'>
                    <label className='container'>
                        <div className='title'>{label}</div>
                        <input
                            type='checkbox'
                            name='grade'
                            ref={register({
                                required: false,
                                validate: (value) => (value.length ? true : 'Please select at least one grade')
                            })}
                            value={value}
                            data-test={`CheckboxCypressId${label}`}
                        />
                        <span className='checkmark' />
                    </label>
                </div>
            );
        });
    };

    const tos = <a tw='text-black' href='https://muzology.com/tos' target='_blank' rel='noreferrer'>Terms of Service</a>;
    const privacy = <a tw='text-black' href='https://muzology.com/privacy' target='_blank' rel='noreferrer'>Privacy Policy</a>;

    const [school, setSchool] = useState('');

    return (
        <div
            id='onboard-stage-agree-to-terms'
            data-testid='onboard-stage-agree-to-terms'
            tw='px-0 sm:px-16 text-center py-10'
            css={[
                css`
                    .error-container {
                        text-align: left;
                    }
                `
            ]}
        >
            <div tw='text-3xl font-extrabold mt-2'> Okay, Let’s Get Rockin’</div>
            <div tw='flex justify-center'>
                <div tw='background[linear-gradient(93.49deg, #1CC8E1 3.49%, #887BE3 100%)] height[3px] w-80 mt-3 rounded-full' />
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div tw='mt-10'>
                    <div tw='mt-5 mb-2 font-medium text-base text-EarieBlack text-center'>
                        What grade(s) do you teach?
                    </div>

                    <CheckBoxGrid tw='mt-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-0 sm:gap-4'>
                        {makeCheckbox()}
                    </CheckBoxGrid>

                    <div> {showError(formErrors.grade)}</div>
                </div>

                <div tw='mt-5'>
                    <div tw='font-medium text-sm text-EarieBlack text-justify'>State</div>
                    <StateSelectListBox cypressName='state-selection' tw='w-24' onSelectItem={selectState} />
                    {showError(formErrors.state)}
                </div>

                <div tw='mt-5'>

                    <div tw='mb-2 font-medium text-sm text-EarieBlack text-justify'>
                        Your Teacher Name <span tw='text-DavysGrey text-xs ml-0.5'>{name_description}</span>
                    </div>

                    <div tw='flex justify-start'>
                        <input
                            tw='bg-white border-gray-200 border-2  px-4 py-3 rounded-md placeholder-DavysGrey text-sm font-medium w-full max-w-[330px]'
                            placeholder='Your name'
                            type='text'
                            name='name'
                            autoFocus
                            ref={register({ required: true })}
                        />
                    </div>
                    {showError(formErrors.name)}
                </div>

                {/*
                <div tw='mt-5 relative'>
                    <SchoolAutoSuggest
                        value={school}
                        onChange={setSchool}
                    />
                </div>
                */}

                {/*
                <div tw='mt-10'>
                    <CheckboxContainer>
                        <label className='container'>
                            <div className='title' tw=''>
                                I agree to the Muzology {tos} and {privacy}.
                            </div>
                            <input type='checkbox' name='tos' ref={register({ required: true })} data-testid='tos' />
                            <span className='checkmark' />
                        </label>
                        {showError(formErrors.tos)}
                    </CheckboxContainer>
                </div>

                <div tw='mt-20 sm:mt-10'>
                    <CheckboxContainer>
                        <label className='container'>
                            <div className='title'>I am eighteen (18) years of age or older</div>
                            <input type='checkbox' name='age' ref={register({ required: true })} data-testid='age' />
                            <span className='checkmark' />
                        </label>
                    </CheckboxContainer>
                    {showError(formErrors.age)}
                </div>
                */}

                <div tw='mt-10'>
                    <button
                        data-testid='submit'
                        type='submit'
                        tw='py-2 px-8 bg-[#1F2365] hover:bg-[#3F4385] active:bg-[#5F63A5] cursor-pointer rounded-lg text-sm font-extrabold leading-normal text-white cursor-pointer min-w-[150px] min-h-[40px]'
                    >
                        AGREE AND CONTINUE
                    </button>
                </div>

                <div tw='flex justify-center'>
                    <div tw='background[linear-gradient(93.49deg, #1CC8E1 3.49%, #887BE3 100%)] height[3px] w-80 mt-6 rounded-full' />
                </div>
            </form>
        </div>
    );
};

export default AgreeToTerms;
