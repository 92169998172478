import React, { useRef } from 'react';
import 'twin.macro';
import PropTypes from 'prop-types';
import { AudioIcon } from '../../assets/icons';

const AudioButton = ({ audioSample=null, children=null, className=null, tabIndex }) => {
    const audioElement = useRef();

    const handleClick = (event) => {
        if (audioElement.current) {
            if (audioElement.current.paused) {
                audioElement.current.play();
            } else {
                audioElement.current.pause();
                audioElement.current.currentTime = 0;
            }
        }
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <button
            tabIndex={tabIndex ?? 1000}
            tw='flex justify-center items-center select-none text-white text-xl cursor-pointer hover:color[#cccccc] active:color[#bbbbbb]'
            className={`audio-button ${className}`}
            onClick={handleClick}
        >
            {children}

            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M16 10C16.591 11.5668 16.5743 12.4325 16 14' stroke='#B5AAD0' strokeWidth='1.5' strokeLinecap='round' />
                <path d='M18 8C19.3333 10 19.3333 14 18 16' stroke='#B5AAD0' strokeWidth='1.5' strokeLinecap='round' />
                <path d='M20 6C22.6667 9 22.6667 15 20 18' stroke='#B5AAD0' strokeWidth='1.5' strokeLinecap='round' />
                <path d='M2 11.5C2 10.1193 3.11929 9 4.5 9H5V14H4.5C3.11929 14 2 12.8807 2 11.5V11.5Z' stroke='#B5AAD0' strokeWidth='1.5' />
                <path d='M5 10.5689C5 9.5034 5.78009 8.61823 6.79418 8.29124C9.24403 7.50127 10.8006 6.07611 12.1714 4.40513C12.7823 3.66047 14 4.05832 14 5.02149V18.6924C14 19.6822 12.7037 20.0774 12.0926 19.2987C10.7025 17.5273 9.1064 16.0533 6.79381 15.2598C5.78428 14.9135 5 14.0292 5 12.9619V10.5689Z' stroke='#B5AAD0' strokeWidth='1.5' />
            </svg>


            <audio
                ref={audioElement}
                className='audio-player-node'
                autoPlay={false}
                src={audioSample}
            />
        </button>
    );
};

// AudioButton.propTypes = {
//     audioSample: PropTypes.string,
//     onClick: PropTypes.func,
//     className: PropTypes.string,
//     children: PropTypes.any,
//     style: PropTypes.object
// };
//
// AudioButton.defaultProps = {
//     audioSample: null, // "http://public.muzology.com/quiz-audio/56f950cd-b2a9-4aaf-be4e-147fea5c678a.mp3",
//     onClick: null,
//     className: null,
//     style: null
// };

export default AudioButton;
