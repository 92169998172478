import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { RecordIndicator, Table } from 'components';
import tw from 'twin.macro';
import { student } from './DummyData';

const ChallengeAttemptsRecordsEarned = ({ student = null }) => {
    let videoStats = Object.values(student?.video_stats);

    // note this is slightly confusing the current_level outside of quiz_level is 1 number greater
    // so in {} quiz_level silver === 1
    // outside of quiz_level silver === 2

    const data = useMemo(
        () => {
            const findTotalRecords = (level) => {
                return videoStats.filter((stat) => stat.current_level >= level).length;
            };

            const findTotalChallengeAttempts = (level) => {
                return videoStats
                    .filter((stat) => stat.quiz_levels?.[level])
                    .map((stat) => stat.quiz_levels)
                    .reduce((accumulator, quiz_level) => accumulator + quiz_level[level].attempts, 0);
            };

            return [
                {
                    col1: <RecordIndicator level='Platinum' />,
                    col2: findTotalRecords(4),
                    col3: findTotalChallengeAttempts(3),
                    col4: Math.round((findTotalChallengeAttempts(3) / findTotalRecords(4)) * 10) / 10
                },
                {
                    col1: <RecordIndicator level='Gold' />,
                    col2: findTotalRecords(3),
                    col3: findTotalChallengeAttempts(2),
                    col4: Math.round((findTotalChallengeAttempts(2) / findTotalRecords(3)) * 10) / 10
                },
                {
                    col1: <RecordIndicator level='Silver' />,
                    col2: findTotalRecords(2),
                    col3: findTotalChallengeAttempts(1),
                    col4: Math.round((findTotalChallengeAttempts(1) / findTotalRecords(2)) * 10) / 10
                }
            ];
        },
        [videoStats]
    );

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'col1'
            },
            {
                Header: 'Records',
                accessor: 'col2'
            },
            {
                Header: (
                    <>
                        Total Challenge
                        <br />
                        Attempts
                    </>
                ),
                accessor: 'col3'
            },
            {
                Header: 'Average Attempts Needed To Earn Record',
                accessor: 'col4'
            }
        ],
        []
    );

    const rowStyle = tw`mb-6`;
    const headerStyle = tw`text-center text-sm text-DarkLiver mb-3 `;
    const cellStyle = tw`flex items-center justify-center text-EarieBlack text-lg`;

    return (
        <div tw='bg-white rounded-lg font-sans'>
            <div tw='pl-4 pt-4'>
                <div tw='text-base font-medium text-EarieBlack'>Challenge Attempts + Records Earned</div>
                <div tw='text-xs text-SonicSilver'>Total number of challenges take and records earned</div>
                <div tw='pt-8 pb-4 pr-9'>
                    <Table
                        columns={columns}
                        data={data}
                        rowStyle={rowStyle}
                        headerStyle={headerStyle}
                        cellStyle={cellStyle} />
                </div>
            </div>
        </div>
    );
};

// Use the dummy data as default props
// once assignment page is hooked up should be able to just pass student data in as a prop
// ChallengeAttemptsRecordsEarned.defaultProps = {
//     student: student
// };

// ChallengeAttemptsRecordsEarned.propTypes = {
//     anyProp: PropTypes.any
// };

export default ChallengeAttemptsRecordsEarned;
