// loading overlay
import React, { useCallback, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { BeatLoader } from 'react-spinners';

const LoadingOverlayContainer = styled.div`
    //z-index: 100000;

        // position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;

    //display: flex;
    //justify-content: center;
    //align-items: center;
    //flex-direction: column;

    //background: rgba(0, 0, 0, 0.4);
    //height: 100vh;
`;

export const LoadingOverlay = ({ show = false, fixed = false, text = null, ...props }) => {
    if (!show)
        return null;
    return (
        <div
            {...props}
            tw='flex flex-col justify-center items-center inset-0'
            css={[
                fixed ? tw`fixed height[100vh]` : tw`absolute`,
                css`
                    z-index: 100000;
                    background: rgba(0, 0, 0, 0.4);
                    //height: 100vh;
                `
            ]}
        >
            <BeatLoader color='#ffffff' />
            {!!text && <div tw='fontSize[32px] color[white] marginTop[10px]'>{text}</div>}
        </div>
    );
};


export const useLoadingOverlay = () => {
    const [isLoading, setIsLoading] = useState(false);

    const Loading = useCallback((props) => {
        return isLoading ? <LoadingOverlay fixed={true} show={true} {...props} /> : null;
    }, [isLoading]);

    return [isLoading, setIsLoading, Loading];
};

export default LoadingOverlay;
