import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import { useCourseAndVideos, useLessons } from 'state/CatalogState';
import { useVideosWithStats } from 'state/Student/VideoStats';
import RenderOnViewPortEntry from 'components/RenderOnViewPortEntry/RenderOnViewPortEntry';
import HomePageVideoRows from 'components/HomePageVideoRows/HomePageVideoRows';
import VideoSlider from 'components/VideoSlider';
import MobileVideoCardRow from 'components/MobileVideoCardRow/MobileVideoCardRow';


const ScrollBarStyle = css`

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }
`;


const NewVideosRow = ({ teacher }) => {

    const newVideosCourse = useCourseAndVideos('new-videos');
    const coursesNotLoaded = Object.entries(newVideosCourse ?? {}).length === 0;
    const videoLibraryWithStats = useVideosWithStats();
    const lessons = useLessons();

    const videoRow = useMemo(() => {
        if (coursesNotLoaded) return null;

        const findLesson = (lessons, lessonId) => Object.values(lessons).find(l => l.id === lessonId);

        let groupLessons = newVideosCourse?.lessons.map(lessonId => findLesson(lessons, lessonId)).filter(l => l);

        let videoRowVideos = groupLessons.map(lesson => {
            const v = videoLibraryWithStats?.find(video => video.id === lesson.video);
            if (!v) {
                console.log('video not found', lesson.video);
            }
            return v;
        });


        return (
            <>
                <RenderOnViewPortEntry key={`${newVideosCourse.id}-1`} threshold={0.25} >
                    <div tw='mt-14 hidden md:block' key={newVideosCourse?.id} css={teacher && tw`mb-14`}>
                        <HomePageVideoRows title={'New Releases'} videos={videoRowVideos} teacher={teacher} videosLoaded={coursesNotLoaded} />
                    </div>
                </RenderOnViewPortEntry>

                <div key={`${newVideosCourse.id}-2`} tw='mt-14 md:hidden'>
                    <h2 tw='text-white text-base tiny:text-xl font-semibold ml-[6vw] max-w-[320px] xsm:max-w-[360px]'>New Releases</h2>
                    <div tw='flex flex-nowrap overflow-x-auto justify-between items-center mt-2 pl-2' css={[ScrollBarStyle]}> <MobileVideoCardRow videos={videoRowVideos} /> </div>
                </div>

            </>
        );

    }, [coursesNotLoaded, newVideosCourse, lessons, videoLibraryWithStats]);


    return videoRow;
};

NewVideosRow.propTypes = {
    anyProp: PropTypes.any,
}

export default NewVideosRow;

