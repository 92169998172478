import React from 'react';
import { css } from 'twin.macro';
import { NavLink, Link } from 'react-router-dom';
import { useSearch } from 'context/SearchProvider';
import { useCourseAndVideos } from 'state/CatalogState';
import LogoHeart from 'static/images/logo/logo_heart@2x.png';
import { useFeatures } from 'state/User/Features';
import AllVideosSVG from '../../../../static/icons/DTC-NavBar/AllVideos/AllVideosSVG';
import GradeSVG from '../../../../static/icons/DTC-NavBar/Grade/GradeSVG';
import MyPlaylistsSVG from '../../../../static/icons/DTC-NavBar/MyPlaylists/MyPlaylistsSVG';
import PlaylistSVG from '../../../../static/icons/DTC-NavBar/Playlist/PlaylistSVG';
import TopicsSVG from '../../../../static/icons/DTC-NavBar/Topics/TopicsSVG';
import { openURL } from '../../../../utils';
import StyledNavigationLink from './StyledLink';
import PlaylistSection from './PlaylistSection';
import { HowItWorksButton, Button } from 'components';
import { MUZOLOGY_WEBSITE_URL } from 'config';
import { useUser, useAccount } from 'state/User/UserState';

const HideSideBar = css`
    @media (min-width: 1280px) {
        width: 180px;
    }
  @media (max-width: 900px) {
    display: none;
  }
`;

const HideBottomBar = css`
  @media (min-width: 900px) {
    display: none;
  }
`;

const ScrollBarStyle = css`
  height: 100vh;

  ::-webkit-scrollbar {
    background: #4D3095;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #6441B9;
    border-radius: 20px;
    max-height: 20px;
  }

  ::-webkit-scrollbar-track {
  }
`;


const SideNav = ({ items, setOverlayVisible, overlayVisible, studentId, timesTablesProduct }) => {

    const { search, setDropDown } = useSearch();
    const isFeatureEnabled = useFeatures();
    // const [overlayVisible, setOverlayVisible] = React.useState(false);

    const handleCloseSearch = () => {
        search(null);
        setDropDown(null);
    };

    const handleClickNavItem = () => {
        if (overlayVisible)
            setOverlayVisible(false);
    };

    // get the courses TODO: remove hard-coded course ids
    const category_course = useCourseAndVideos('course-by-category');
    const category_grades = useCourseAndVideos('course-by-grade');
    const courseGetRootGroups = (course) => {
        return course?.groups?.filter(item => item.parent === null) ?? [{}];
    };
    const studentIdPath = studentId ? `/${studentId}` : '';

    const user = useUser();
    const account = useAccount();
    const handleOnClickUpgradeButton = () => {
        window.open(
            `${MUZOLOGY_WEBSITE_URL}checkout?stage=1&email=${encodeURIComponent(user.email)}&a=${account.account_uuid}`,
            '_blank',
            'noopener,noreferrer'
        );
    };
    return (
        <>
            {/* Overlay */}
            {overlayVisible && (
                <div tw='fixed top-0 left-0 w-screen h-screen bg-[#3E257A] flex items-center justify-center'>
                    <div tw='absolute top-8 w-full px-5'>
                        <div tw='flex justify-between items-center w-full' onClick={() => setOverlayVisible(false)}>

                            <NavLink
                                to='/'
                                onClick={() => {
                                    search(null);
                                    setDropDown(null);
                                }}>
                                <div tw='flex items-center justify-center'>
                                    <img id='muzology-logo' tw='h-12 w-auto' src={LogoHeart} alt='Muzology Logo' />
                                </div>
                            </NavLink>

                            <svg
                                xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'
                                fill='none'>
                                <rect
                                    x='3.41406' y='19.2617' width='23' height='2' rx='1'
                                    transform='rotate(-45 3.41406 19.2617)'
                                    fill='white' />
                                <rect
                                    x='4.41406' y='3.26172' width='23' height='2' rx='1'
                                    transform='rotate(45 4.41406 3.26172)'
                                    fill='white' />
                            </svg>

                        </div>
                    </div>

                    <div tw='flex flex-col space-y-8 text-white -mt-20'>
                        {!timesTablesProduct &&
                            <Link
                                tw='flex items-center pb-5'
                                to={`playlists/${category_grades.id}/${courseGetRootGroups(category_grades)[0].id}${studentIdPath}`}
                                onClick={() => setOverlayVisible(false)}
                            >
                                <GradeSVG />
                                <div tw='ml-2 font-Poppins text-xl font-medium leading-normal text-white'>
                                    Playlists By Grade
                                </div>
                            </Link>
                        }

                        {!timesTablesProduct &&
                            <Link
                                tw='flex items-center pb-5'
                                to={`playlists/${category_course.id}/${courseGetRootGroups(category_course)[0].id}${studentIdPath}`}
                                onClick={() => setOverlayVisible(false)}
                            >
                                <TopicsSVG />
                                <div tw='ml-2 font-Poppins text-xl font-medium leading-normal text-white'>
                                    Playlist By Topic
                                </div>
                            </Link>
                        }



                        {!timesTablesProduct &&
                            <Link
                                to='playlists/all_videos/all'
                                onClick={handleClickNavItem}
                                tw='flex items-center pb-5'
                            >
                                <AllVideosSVG />
                                <div tw='ml-2 font-Poppins text-xl font-medium leading-normal text-white'>
                                    All Videos
                                </div>
                            </Link>
                        }
                        {timesTablesProduct &&
                            <Link
                                to='playlists/1090c873-05d0-4161-99a9-78acc86a09fd'
                                onClick={handleClickNavItem}
                                tw='flex items-center pb-5'
                            >
                                <AllVideosSVG />
                                <div tw='ml-2 font-Poppins text-xl font-medium leading-normal text-white'>
                                    Times Tables
                                </div>
                            </Link>
                        }
                        {timesTablesProduct &&
                            <Link
                                to='playlists/c9fefed9-d66c-4b6d-afeb-0a8ca28f40ab'
                                onClick={handleClickNavItem}
                                tw='flex items-center pb-5'
                            >
                                <TopicsSVG />
                                <div tw='ml-2 font-Poppins text-xl font-medium leading-normal text-white'>
                                    Bonus Videos
                                </div>
                            </Link>
                        }

                        <Link
                            tw='flex items-center pb-5'
                            to={`playlists/favorites${studentIdPath}`}
                            onClick={handleClickNavItem}
                        >
                            <MyPlaylistsSVG />
                            <div tw='ml-2 font-Poppins text-xl font-medium leading-normal text-white'>My Favorites</div>
                        </Link>


                    </div>
                </div>
            )}

            <div tw='w-0 hideSideNav:w-[60px] iPadPro:w-[121px] largeSideNav:w-[180px] fixed h-full bg-[#3E257A] pt-24 md:block' css={[ScrollBarStyle]}>
                <div tw='flex flex-col items-center h-full w-full mx-auto overflow-y-auto'>
                    {items
                        .filter(({ feature }) => {
                            // check feature flags to see if this item should be shown
                            if (feature) {
                                return isFeatureEnabled(feature);
                            }
                            return true;
                        })
                        .map(({ path, title, redirect, icon, activeIcon, pathMatch }) => {
                            // console.log('item', title, path, `${path}${studentIdPath}`);
                            if (redirect) {
                                // redirect to another site
                                return (
                                    <div
                                        key={path}
                                        onClick={() => openURL(path)}
                                        tw='text-white text-lg font-extrabold cursor-pointer'>
                                        {title}
                                    </div>
                                );
                            } else return (
                                <StyledNavigationLink
                                    key={path}
                                    handleCloseSearch={handleCloseSearch}
                                    path={`${path}${studentIdPath}`}
                                    title={title}
                                    activeIcon={activeIcon}
                                    icon={icon}
                                    pathMatch={pathMatch}
                                />
                            );
                        })}

                    <div tw='w-full flex items-center justify-center my-[1rem] iPadPro:my-[1.9rem]  px-4'>
                        <div tw='w-full bg-[#1C0057] h-[1px]' />
                    </div>

                    <div tw='text-[#C2C3CB] text-xs font-medium uppercase text-left w-full px-4 hidden iPadPro:block'>Playlists</div>
                    <div tw='mt-4' />
                    <PlaylistSection handleCloseSearch={handleCloseSearch} studentId={studentId} timesTablesProduct={timesTablesProduct} />

                    <div tw='w-full px-4' >
                        <HowItWorksButton />
                    </div>

                </div>
            </div>

            <div tw='fixed bg-[#170934] pt-2 bottom-0 h-20 w-full' css={[HideBottomBar]}>
                <div tw='flex items-center h-16 mx-auto overflow-y-auto'>
                    {items
                        .filter(({ feature }) => {
                            // check feature flags to see if this item should be shown
                            if (feature) {
                                return isFeatureEnabled(feature);
                            }
                            return true;
                        })
                        .map(({ path, title, redirect, icon, activeIcon, pathMatch }) => {
                            if (redirect) {
                                // redirect to another site
                                return (
                                    <div
                                        key={path} onClick={() => {
                                            openURL(path);
                                            setOverlayVisible(false);
                                        }} tw='text-white text-lg font-extrabold cursor-pointer'>
                                        {title}
                                    </div>
                                );
                            } else return (
                                <StyledNavigationLink
                                    key={path}
                                    handleCloseSearch={handleCloseSearch}
                                    path={path}
                                    title={title}
                                    activeIcon={activeIcon}
                                    icon={icon}
                                    onClick={() => setOverlayVisible(false)}
                                    pathMatch={pathMatch}
                                />
                            );
                        })}

                    <div
                        tw='flex-col items-center flex relative h-full pt-2.5 w-full px-4'
                        onClick={() => setOverlayVisible(true)}>
                        <PlaylistSVG />
                        <div tw='text-[#C2C3CB] text-xs font-medium uppercase text-left  px-4 block'>Playlists</div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default SideNav;
