import AppContainer from 'app/AppContainer';
import React from 'react';
import AppState from 'state';
import UserState from 'state/User/UserState';
import PresenceProvider from '../context/PresenceProvider';
import NotificationProvider from '../context/NotificationProvider';
import PusherProvider from '../context/PusherProvider';
import EventProvider from '../context/EventProvider';
import ServiceProvider from '../context/ServiceProvider';
import CatalogState from '../state/CatalogState';
import LoadingContext from '../context/LoadingContext';
import HelpScout from '../lib/integrations/helpscout';
import LogRocketProvider from '../context/analytics/LogRocketProvider';
import RollbarSetup from '../context/analytics/RollbarSetup';
import SearchProvider from '../context/SearchProvider';

const AuthenticatedApp = ({ initialStore }) => {
    console.debug('[AuthenticatedApp] render');
    let searchIndex = '';

    return (
        <LoadingContext>
            <UserState initialStore={initialStore}>
                <ServiceProvider>
                    <PusherProvider>
                        <NotificationProvider>
                            <EventProvider>
                                <PresenceProvider>
                                    <AppState>
                                        <SearchProvider searchIndex={searchIndex}>
                                            <AppContainer />
                                        </SearchProvider>
                                    </AppState>
                                </PresenceProvider>
                            </EventProvider>
                        </NotificationProvider>
                    </PusherProvider>
                </ServiceProvider>
            </UserState>
        </LoadingContext>
    );
};

export default AuthenticatedApp;
