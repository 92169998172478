import React, { useMemo, useState } from 'react';
import 'twin.macro';
import { Button, CancelSubscriptionPopUp } from 'components';
import { useAccount, useSubscription } from 'state/User/UserState';
import moment from 'moment';
import useAxios from 'axios-hooks';
import useSWR from 'swr';
import LoadingOverlay from '../../../../../components/LoadingOverlay';
import { useDevTool } from '../../../../../lib/DevTool/DevTool';
import { apiFetcher } from '../../../../../utils/api-client';
import FreeTrial from './FreeTrial';
import PaypalSubscription from './PaypalSubscription';
import StripeSubscription from './StripeSubscription';
// import ParentSettingsModal from './Modal/ParentSettingsModal';
// import Subscription from './Modal/Subscription';

const ManageSubscription = () => {
    // const [isOpen, setIsOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const subscription = useSubscription();
    const account = useAccount();
    console.log('🚀 ~ ManageSubscription ~ subscription:', subscription);

    /*
    TODO: fetch updated subscription
    const { account_variant } = useAccount();
    const original = useSubscription();
    const { data, error, isValidating } = useSWR('/api/subscription/', apiFetcher);
    const subscription = data ?? original ?? {};
    */

    const isStepUp = subscription?.source === 'ariba';
    const isStripe = subscription?.source === 'stripe';
    const isPayPal = subscription?.source === 'paypal';
    useDevTool('ManageSubscription', { subscription, isStepUp, isStripe, isPayPal });
    // let duration = '';
    // if (isStripe && subscription) {
    //     if (subscription.duration === 'Monthly') {
    //         duration = `${subscription.price} / Month`;
    //     } else if (subscription.duration === 'Yearly') {
    //         duration = `${subscription.price} / Year`;
    //     }
    // }

    const subscriptionStatus = useMemo(() => {
        if (!subscription) {
            if (account.is_trial) {
                return (<FreeTrial account={account} setLoading={setLoading}/>);
            } else {
                return (<div>No subscription found</div>);
            }
        }

        if (subscription?.source === 'stripe') {
            return (<StripeSubscription subscription={subscription} setLoading={setLoading}/>);
        } else if (subscription?.source === 'paypal') {
            return (<PaypalSubscription subscription={subscription} setLoading={setLoading}/>);
        } else if (subscription?.source === 'ariba') {
            return (<>
                <div tw='pt-6 sm:pt-0'>Your Muzology Subscription</div>

                <div tw='mt-5'>
                    Source: <span tw='font-black'>Step Up for Students</span>
                </div>

                <div tw='mt-5 text-white'>
                    Status: <span tw='font-black'>{subscription?.status?.toUpperCase()}</span>
                </div>
            </>);
        } else {
            return (<div>Unknown subscription source {subscription.source}</div>);
        }
    }, [account, subscription]);

    return (
        <div tw='relative text-white text-lg flex-col sm:mt-14 px-4 sm:p-0'>

            <LoadingOverlay show={loading} fixed={true}/>

            {subscriptionStatus}

        </div>
    );
};

export default ManageSubscription;
